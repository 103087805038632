// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
//= require navbar
//= require sidebar
//= require bootstrap
//= require simplemde
//= require image_uploader
//= require nava_ajax_request
//= require generate_form
//= require post
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import toastr from 'toastr';
import "chartkick/chart.js";
import './simplemde'
import './nava_ajax_request'
import './generate_form'
import './image_uploader'
import './post'
import './follow_unfollow'
import './canvas_star'

export function loadNavaGyanEditor(params, callback) {
  var simplemde = new SimpleMDE(
    {
      element: $(`${params['element']}`)[0],
      indentWithTabs: true,
      placeholder: params['title'],
      insertTexts: {
        horizontalRule: ["", "\n\n-----\n\n"],
      },
      showIcons: ['code', 'table'],
      renderingConfig: {
        codeSyntaxHighlighting: true
      },
      toolbar: ['bold', 'heading', 'italic', 'strikethrough', '|', 'code', 'quote', 'ordered-list', 'unordered-list', '|', 'link', 'image', 'table', '|', 'preview', 'guide'],
      guide: {
        name: 'Markdown guide',
        action: '/posts/editor_guide'
      }
    }
  );
  if (params['is_custom']) {
    params['container'].find(".CodeMirror").css("border", "2px solid #0d6efd");
    params['container'].find(".CodeMirror-wrap textarea").on("input", function () {
      var codeMirrorLine = params['container'].find(".CodeMirror-code");
      params['container'].find(".CodeMirror").css("height", "auto");
      var newHeight = codeMirrorLine.height() + 60;
      params['container'].find(".CodeMirror").css("height", newHeight);
    });
  }

  if (params['fixedToolbar']) {
    var editorToolbar = document.querySelector('.editor-toolbar');
    editorToolbar.id = 'editor-toolbar';
    var editorWrapper = document.querySelector('.CodeMirror-wrap');
    // editorWrapper.style.paddingTop = editorToolbar.offsetHeight + 'px';

    window.addEventListener('scroll', function () {
      var scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop >= editorWrapper.offsetTop && sessionStorage.getItem("theme") != "dark") {
        var codeMirrorEditor = document.querySelector('.CodeMirror.cm-s-paper.CodeMirror-wrap');
        if (codeMirrorEditor) {
          editorToolbar.style.background = '#fff';
          editorToolbar.style.position = 'fixed';
          editorToolbar.style.top = '56px';
          editorToolbar.style.zIndex = 1000;
          editorToolbar.style.opacity = 3;
          editorToolbar.style.width = codeMirrorEditor.offsetWidth + 'px';
        }
      } else {
        editorToolbar.style.top = '';
        editorToolbar.style.position = 'relative';
        editorToolbar.style.opacity = 0.6;
        editorToolbar.style.width = '100%';
      }
    });
  }

  if (typeof callback === "function") {
    callback();
  }

  if (params['initial_value']) {
    simplemde.value(params['initial_value']);
  }
}

function showMoreOrLess(target, show_more_btn, show_less_btn, show_items = 3) {
  var listItems = $(target);
  var totalItems = listItems.length;

  $(show_more_btn).hide();
  $(show_less_btn).hide();

  if (totalItems > show_items) {
    listItems.slice(show_items).addClass('hidden-item');

    $(show_more_btn).show();

    $(show_more_btn).on('click', function () {
      listItems.removeClass('hidden-item');
      $(this).hide();
      $(show_less_btn).show();
    });

    $(show_less_btn).on('click', function () {
      listItems.slice(show_items).addClass('hidden-item');
      $(this).hide();
      $(show_more_btn).show();
    });
  }
}

function setMultipleChoice(limit = 5, className = '', targetId = '', choicesSelected = []) {
  var nava_tag_url = '/nava_tags/';
  var choices = []
  // Below should be instance of choice:
  // choices = [{ value: 'One', label: 'Label One', disabled: false, selected: false }]
  if (className == '.post-tags') {
    var data = undefined;
    if (targetId != '') {
      var data = {
        post_id: targetId
      };
    }
    nava_ajax_req(nava_tag_url, 'GET', 'JSON', data, function (res) {
      if (res.success === true) {
        var tags = res.nava_tags
        for (var i = 0; i < tags.length; i++) {
          var tag = tags[i];
          choices.push(tag);
        }
      }
      setChoices(limit, className, choices);
    });
  } else if (className == '.post-category') {
    choices = [
      {
        value: 'category 1',
        label: 'category 1',
        disabled: false,
        selected: false
      },
      {
        value: 'category 2',
        label: 'category 2',
        disabled: false,
        selected: false
      }
    ];
    setChoices(limit, className, choices);
  } else if (className == '.post-type') {
    choices = [
      {
        value: 'type 1',
        label: 'type 1',
        disabled: false,
        selected: false
      },
      {
        value: 'type 2',
        label: 'type 2',
        disabled: false,
        selected: false
      }
    ];
    setChoices(limit, className, choices);
  } else if (className == '.post-status') {
    choices = [
      {
        value: 'draft',
        label: 'Draft',
        disabled: false,
        selected: choicesSelected.includes('draft') ? true : false
      },
      {
        value: 'publish',
        label: 'Publish',
        disabled: false,
        selected: choicesSelected.includes('publish') ? true : false
      }
    ];
    setChoices(limit, className, choices);
  } else {
    var multipleCancelButton = new Choices(className, {
      removeItemButton: true
    });
  }
}

function setChoices(limit, className, choices) {
  var multipleCancelButton = new Choices(className, {
    removeItemButton: true,
    choices: choices,
    maxItemCount: limit
  });
}

// Function to convert a string like '188k Likes' to an integer (e.g., 188000)
function convertStringToIneger(likesString) {
  const units = {
    'k': 1e3,     // Thousand
    'M': 1e6,     // Million
    'B': 1e9,     // Billion
    'T': 1e12,    // Trillion
    'Q': 1e15,    // Quadrillion
    'Qu': 1e18,   // Quintillion
    // Add more units as needed
  };

  const numericPart = likesString.replace(/[^\d.]/g, '');
  const unit = likesString.replace(/[.\d\s]/g, '');

  if (units[unit]) {
    return parseFloat(numericPart) * units[unit];
  } else {
    return parseInt(numericPart, 10);
  }
}

// Function to convert an integer to a formatted string with unit (e.g., 188000 to '188k')
function convertInegerToString(integer) {
  const units = {
    1e3: 'k',
    1e6: 'M',
    1e9: 'B',
    1e12: 'T',
    1e15: 'Q',
    1e18: 'Qu',
    // Add more units as needed
  };

  for (const unitValue of Object.keys(units).reverse()) {
    const unitAbbreviation = units[unitValue];
    if (integer >= unitValue) {
      const formattedValue = (integer / unitValue).toFixed(1);
      return `${formattedValue}${unitAbbreviation}`;
    }
  }

  return integer.toString();
}

function scrollPage(targetId, ReduceOffsetValue, speed) {
  var targetDiv = $(targetId);

  $('html, body').animate({
    scrollTop: targetDiv.offset().top - ReduceOffsetValue
  }, speed); // Adjust the scroll speed as needed
}

function warnForDelete(message = 'Are you sure you want to delete this item?') {
  const confirmed = confirm(message);
  if (!confirmed) {
    event.preventDefault(); // Prevent the default action if not confirmed
  }
}

document.addEventListener('turbo:load', () => {
  let userBio = document.getElementsByClassName('truncate-text');
  alluserBios = Array.from(userBio)

  alluserBios.forEach((bio) => {
    let maxLength = 68;
    let text = bio.innerText;

    if (text.length > maxLength) {
      let truncatedText = text.substring(0, maxLength) + '...';
      bio.innerText = truncatedText;
    }
  })
});

function showNotification(type, message) {
  toastr.options = {
    closeButton: true,
    positionClass: 'toast-top-right mt-5',
    onclick: null,
    showDuration: '300',
    hideDuration: '3000',
    timeOut: '2000',
    extendedTimeOut: '2000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    progressBar: true,
    hideMethod: 'fadeOut'
  };
  toastr[type](message);
}

window.showNotification = showNotification;
window.loadNavaGyanEditor = loadNavaGyanEditor;
window.showMoreOrLess = showMoreOrLess;
window.setMultipleChoice = setMultipleChoice;
window.convertStringToIneger = convertStringToIneger;
window.convertInegerToString = convertInegerToString;
window.scrollPage = scrollPage;
window.warnForDelete = warnForDelete;
