function navaFollow(followedType, followedId, buttonContainerId, buttonClasses = 'btn btn-secondary') {
  var data = {
    followed_type: followedType,
    followed_id: followedId
  };

  // Send a POST request to follow the user
  nava_ajax_req('/follows', 'POST', 'JSON', data, function(res) {
    if (res.message == 'followed') {
      updateFollowButton(buttonContainerId, 'Following', buttonClasses, function() {
        navaUnFollow(followedType, followedId, buttonContainerId, buttonClasses);
      });
    }
  });
}

function navaUnFollow(followedType, followedId, buttonContainerId, buttonClasses = 'btn btn-secondary') {
  var data = {
    followed_type: followedType,
    followed_id: followedId
  };

  // Send a DELETE request to unfollow the user
  nava_ajax_req('/follows/' + followedId, 'DELETE', 'JSON', data, function(res) {
    if (res.message == 'unfollowed') {
      updateFollowButton(buttonContainerId, 'Follow', buttonClasses, function() {
        navaFollow(followedType, followedId, buttonContainerId, buttonClasses);
      });
    }
  });
}

// Function to update the follow button
function updateFollowButton(buttonContainerId, buttonText, buttonClasses, onClickFunction) {
  var button = $('<button>', {
    class: buttonClasses + ' nava-black-bg',
    text: buttonText,
    click: onClickFunction
  });

  $('#' + buttonContainerId).empty().append(button);
}

window.navaFollow = navaFollow;
window.navaUnFollow = navaUnFollow
