 function generateReplyForm(commentId, formContainer, nestedReply = true) {
  var formExists = formContainer.find('form').length > 0;

  if (formExists || formContainer.children('form').length > 0) {
    $('#' + formContainer[0].id).toggle();
  } else {
    var url = '/comments/' + commentId + '/replies/new';
    var formContainerId = '#reply-form-container-' + commentId;
    var repliesSectionId = '#replies-section-' + commentId;
    var data = {
      form_container_id: formContainerId,
      replies_section_id: repliesSectionId
    };

    nava_ajax_req(url, 'GET', 'script', data);
  }
}
 
 function generateEditForm(commentId, formContainer, reply = true) {
  var formExists = formContainer.find('form').length > 0;
  var contentSection = "#content-section-" + commentId
  
  if (reply === true) {
    var url = '/replies/' + commentId + '/edit';
  } else {
    var url = '/comments/' + commentId + '/edit';
  }

  if (formExists || formContainer.children('form').length > 0) {
    formContainer.show();
    $(contentSection).hide();
  } else {
    nava_ajax_req(url, 'GET', 'script', undefined);
  }
}

window.generateReplyForm = generateReplyForm;
window.generateEditForm = generateEditForm;
