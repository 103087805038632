function imageUploadUrl(callback) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  // Show the spinner while uploading
  const spinner = document.getElementById('spinner');

  if (typeof event === 'object' && event.target instanceof HTMLInputElement && event.target.type === 'file') {
    // Handle file selection from the file input
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);

    const headers = new Headers();
    headers.append('X-CSRF-Token', csrfToken); // Include the CSRF token

    var success = false;
    var metadata = {};
    // spinner.style.display = 'block';
    showSpinner();
    fetch('/upload', {
      method: 'POST',
      headers: headers,
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        success = true;
        metadata['url'] = data.url;
        metadata['secure_url'] = data.secure_url;

        const res = {
          data: metadata,
          success: success
        };
        // Hide the spinner on success
        // spinner.style.display = 'none';
        hideSpinner();
        callback(res);
      })
      .catch(error => {
        console.error('Error uploading file:', error);

        const res = {
          data: metadata,
          success: success
        };
        // Hide the spinner on success
        // spinner.style.display = 'none';
        hideSpinner();
        callback(res);
      });
  } else {
    // Create and trigger the file input element
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.style.display = 'none';

    fileInput.addEventListener('change', (event) => {
      imageUploadUrl(callback);
    });

    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  }
}

function showSpinner() {
  const spinnerOverlay = document.getElementById('spinner-overlay');
  spinnerOverlay.style.display = 'flex';
}

function hideSpinner() {
  const spinnerOverlay = document.getElementById('spinner-overlay');
  spinnerOverlay.style.display = 'none';
}

window.imageUploadUrl = imageUploadUrl;
window.showSpinner = showSpinner;
window.hideSpinner = hideSpinner;
