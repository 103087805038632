function showExpandedCode() {
  $('.expand-code').on('click', function(event) {
    event.preventDefault();
    
    const codeBlockContent = $(this).prev().prev().find('code').text();
    showModal('View Larger Code', codeBlockContent);
    // $('pre').addClass('remove-code-style');
  });
}

function showModal(title, content) {
  const modal = `
    <div class="modal navagyan-modal">
      <div class="modal-content navagyan-modal-content">
        <a href="javascript:void(0);" class="copy-code text-decoration-none"><i class="bi bi-clipboard"></i></a>
        <i class="bi bi-fullscreen close-modal"></i>
        <br>
        <pre><code>${content}</code></pre>
      </div>
    </div>
  `;
  
  $('body').append(modal);
  
  $('.navagyan-modal .close-modal').click(function() {
    $('.navagyan-modal').empty().remove();
    $('pre').removeClass('remove-code-style');
  });
  
  copyCode();
}

function copyCode() {
  $('.copy-code').on('click', function(event) {
    event.preventDefault();
    const codeBlockContent = $(this).prev().find('code').text();
    const copyLink = $(this);
    copyToClipboard(codeBlockContent);
    copyLink.html('<i class="bi bi-clipboard-check"></i> Copied');
    // Display the 'Copied' text for 2 seconds, then reset the link text
    setTimeout(function() {
      copyLink.html('<i class="bi bi-clipboard"></i>');
    }, 2000);
  });
}

function copyToClipboard(text) {
  const tempTextarea = $('<textarea>').val(text).appendTo('body').select();
  document.execCommand('copy');
  tempTextarea.remove();
}

function loadCodeWraper() {
  $('pre code.prettyprint').each(function() {
    const codeBlock = $(this).closest('pre');
    const codeText = $(this).text();
    
    codeBlock.wrap('<div class="code-block-container"></div>');
    codeBlock.after('<a href="javascript:void(0);" class="copy-code text-decoration-none" style="font-size:12px"><i class="bi bi-clipboard"></i></a><a href="javascript:void(0);" class="expand-code text-decoration-none"><i class="bi bi-fullscreen"></i></a>');
  });
}

function calPostMessage(increment = true) {
  var messageContainer = $('#total-post-comment');
  var oldPostComments = messageContainer.text();
  var messageCount = convertStringToIneger(oldPostComments);
  if (increment == true) {
    messageCount += 1;
  } else {
    messageCount -= 1;
  }
  messageContainer.text(messageCount);
  $('.total-post-comment').text(messageCount);
}

function createOrDestroyBookmark(postId) {
  var data = {
    post_id: postId,
  };
  nava_ajax_req('/bookmarks/', 'POST', 'script', data);
}

function createOrDestroyLike(commentId) {
  var data = {
    comment_id: commentId,
    like: {
      like_icon: 'heart'
    }
  };

  var url = '/likes/';

  nava_ajax_req(url, 'POST', 'script', data, function(result) {
    var likeContainer = $('#like-' + commentId).text();
    var likesRemovedTextCount = likeContainer.replace("Likes", "").trim();
    var likeCount;

    if (likesRemovedTextCount === '' || likesRemovedTextCount === undefined) {
      likeCount = 0
    } else {
      likeCount = convertStringToIneger(likesRemovedTextCount);
    }

    if ($('#path-' + commentId).hasClass('like-reacted')) {
      $('#path-' + commentId).removeClass('like-reacted');
      $('#path-' + commentId).attr('fill', '');;
      likeCount -= 1;
    } else {
      $('#path-' + commentId).addClass('like-reacted');
      $('#path-' + commentId).attr('fill', 'red');
      likeCount += 1;
    }
    
    if (likeCount > 0) {
      var stringFormattedCount = convertInegerToString(likeCount);
      $('#like-' + commentId).text(stringFormattedCount + ' Likes');
    } else {
      $('#like-' + commentId).text('');
    }
  });
}

var replies_loading = false;

function loadMoreReplies(event) {
  var parentCommentId = event.getAttribute('data-parent-comment-id');
  var postId = event.getAttribute('data-post-id');
  var replyPage = event.getAttribute('data-currentpage');

  if (replies_loading) {
    return;
  }

  $(".load-more-replies" + parentCommentId).text('Loading..');
  replies_loading = true;
  replyPage++;
  var data = { 
    page: replyPage, 
    post_id: postId,
    container_id: '#replies-section-' + parentCommentId,
    parent_comment_id: parentCommentId
  };
  var url = '/comments'

  nava_ajax_req(url, 'GET', 'script', data, function(res) {
    replies_loading = false;
    $(".load-more-replies" + parentCommentId).text('Load more replies');
  });
}

window.loadMoreReplies = loadMoreReplies;

window.showExpandedCode = showExpandedCode;
window.showModal = showModal;
window.copyCode = copyCode;
window.copyToClipboard = copyToClipboard;
window.loadCodeWraper = loadCodeWraper;
window.calPostMessage = calPostMessage;
window.createOrDestroyBookmark = createOrDestroyBookmark;
window.createOrDestroyLike = createOrDestroyLike;
