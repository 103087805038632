function nava_ajax_req(url, method, data_type, data, callback) {
  $.ajax({
    url: url,
    method: method,
    dataType: data_type,
    data: data,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    success: function(response) {
    },
    error: function(xhr, status, error) {
      if (xhr.status === 401) {
        window.location.href = '/users/sign_in';
      } else {
        console.log('Error:', xhr.responseText);
      }
    }
  }).done(function (response) {
    if (typeof callback === "function") {
      callback(response);
    }
    
  }).fail(function (xhr, status, error) {
  });
}
window.nava_ajax_req = nava_ajax_req;
